<template>
  <b-row
    id="driver-row"
    class="gx-2 flex-nowrap"
    :class="{ 'driver-col-collapsed': driverColCollapsed }"
  >
    <b-col id="driver-search-col" cols="auto">
      <div class="box full-box overflow-auto pt-0">
        <div class="driver-top-title">
          <div class="float-right top-control">
            <b-button
              v-b-modal.modal-new-driver
              v-if="!driverColCollapsed"
              size="sm"
              variant="outline-primary"
              class="mr-1"
            >
              <b-icon-plus />
              新增司機
            </b-button>

            <b-modal
              id="modal-new-driver"
              title="新增司機"
              ok-title="Save"
              @ok="updateDriver(newDriverForm)"
              @close="newDriverForm = {}"
              @cancel="newDriverForm = {}"
            >
              <div class="mb-3">
                <b-form-input
                  v-model="newDriverForm.name"
                  placeholder="名字"
                ></b-form-input>
              </div>
              <b-form-textarea
                v-model="newDriverForm.note"
                placeholder="備註"
              ></b-form-textarea>
            </b-modal>

            <b-button
              variant="outline-primary"
              size="sm"
              id="btn-toggleDriver"
              @click="toggleDriverCol"
            >
              <b-icon
                :icon="
                  driverColCollapsed ? 'arrow-bar-right' : 'arrow-bar-left'
                "
              ></b-icon>
            </b-button>
          </div>

          <h1 class="box-title">搜尋司機 / Search</h1>
        </div>

        <b-row class="gx-2 mb-2">
          <b-col cols="3">名字</b-col>
          <b-col cols="5" v-if="!driverColCollapsed">備註</b-col>
        </b-row>

        <b-row
          :class="{
            'gx-1': true,
            'mb-2': true,
            'justify-content-between': true,
            'active-driver':
              selectedDriver && d.driverId == selectedDriver.driverId,
          }"
          v-for="(d, driver_index) in drivers"
          :key="`driver-row-${d.driverId || driver_index}`"
        >
          <b-col :cols="!driverColCollapsed ? 3 : 7">
            <b-form-input v-model="d.name"></b-form-input>
          </b-col>
          <b-col cols="5" class="pl-2 pr-0" v-if="!driverColCollapsed">
            <b-form-input v-model="d.note"></b-form-input>
          </b-col>
          <b-col :cols="!driverColCollapsed ? 4 : 'auto'" class="px-1">
            <div class="d-flex">
              <template v-if="!driverColCollapsed">
                <b-button variant="link" @click="updateDriver(d)" size="sm">
                  <b-spinner
                    label="Spinning"
                    small
                    v-if="driverSaving[d.driverId]"
                  ></b-spinner>
                  <b-icon icon="pencil" v-else></b-icon>
                </b-button>
                <b-button
                  variant="link"
                  size="sm"
                  @click="deleteDriver(d.driverId, driver_index)"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
              <b-button
                size="sm"
                variant="outline-primary"
                class="py-2 flex-grow-1"
                @click="selectDriver(d)"
              >
                負責訂單
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="auto" id="driver-order-col">
      <div class="box full-box">
        <template v-if="showOrderTable">
          <b-row>
            <b-col cols="auto">
              <h2 class="box-title">司機訂單 / Driver's order</h2>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <DateRangeOptions
                v-model="dateRange"
                openPosition="left"
                defaultRange="Date Range"
              />
            </b-col>
          </b-row>

          <b-modal
            size="sm"
            :visible="tipsModalVisible"
            hide-header
            hide-footer
          >
            <label for="tips-input">Tips</label>
            <b-form-input
              type="number"
              min="0"
              id="no-pay-cost-input"
              v-model.number="modalDataItem.tips"
            ></b-form-input>

            <div class="text-right mt-3">
              <b-button
                size="sm"
                class="mr-2"
                variant="primary"
                @click="updateDriverOrder(modalDataItem)"
                >Save</b-button
              >
              <b-button
                @click="hideModals()"
                size="sm"
                variant="outline-primary"
                >Cancel</b-button
              >
            </div>
          </b-modal>
          <b-modal
            size="sm"
            :visible="noPayCostModalVisible"
            hide-header
            hide-footer
          >
            <label for="no-pay-cost-input">不用收錢</label>
            <b-form-input
              type="number"
              min="0"
              id="no-pay-cost-input"
              v-model.number="modalDataItem.no_pay_cost"
            ></b-form-input>

            <div class="text-right mt-3">
              <b-button
                size="sm"
                class="mr-2"
                variant="primary"
                @click="updateDriverOrder(modalDataItem)"
                >Save</b-button
              >
              <b-button
                size="sm"
                variant="outline-primary"
                @click="hideModals()"
                >Cancel</b-button
              >
            </div>
          </b-modal>
          <b-modal :visible="remarkModalVisible" hide-header hide-footer>
            <label for="remark-input">Remark</label>
            <b-form-textarea
              class="mb-3"
              id="remark-input"
              v-model="modalDataItem.remark"
            ></b-form-textarea>

            <label for="instruction-input">Instruction</label>
            <b-form-textarea
              id="instruction-input"
              v-model="modalDataItem.instruction"
            ></b-form-textarea>

            <div class="text-right mt-3">
              <b-button
                size="sm"
                class="mr-2"
                variant="primary"
                @click="updateDriverOrder(modalDataItem)"
                >Save</b-button
              >
              <b-button
                size="sm"
                variant="outline-primary"
                @click="hideModals()"
                >Cancel</b-button
              >
            </div>
          </b-modal>

          <b-table
            sticky-header
            head-variant="light"
            responsive
            :no-border-collapse="false"
            :items="driverOrders"
            :fields="driverOrderFields"
            foot-clone
            :show-empty="true"
            tfoot-tr-class="text-center"
            tfoot-class="footer-sticky"
            :class="{
              'empty-table': $_.isEmpty(driverOrders),
            }"
            :tbody-tr-class="driverOrdersRowClass"
          >
            <template #empty>
              <div class="box outline-box p-4">
                <p class="text-center m-0 h6">
                  No order for
                  {{
                    dateHelpers.dateRangeFormat(
                      dateRange.startDate,
                      dateRange.endDate
                    )
                  }}
                </p>
              </div>
            </template>
            <template #thead-top>
              <b-tr class="thead-top">
                <b-th colspan="5"></b-th>
                <b-th colspan="2" class="text-center">公司代收</b-th>
                <b-th colspan="3"></b-th>
              </b-tr>
            </template>
            <template #foot()>
              <span></span>
            </template>
            <template #cell(datetime)="data">
              <div class="text-nowrap">
                <div class="mb-2">
                  {{ data.item.date | formatDate("MMM DD, YYYY") }}<br />{{
                    data.item.time
                  }}
                </div>
                <p>
                  <OrderModal
                    @orderSubmitted="orderUpdated"
                    buttonSize="sm"
                    buttonVariant="link"
                    :order="data.item"
                    >編輯訂單

                    <b-icon-exclamation-circle-fill
                      v-if="
                        ['confirmed', 'waiting'].indexOf(
                          data.item.status.toLowerCase()
                        ) < 0
                      "
                    ></b-icon-exclamation-circle-fill>
                  </OrderModal>
                </p>

                <div class="box payment-box" v-if="data.item.payment_method">
                  {{ data.item.payment_method }}
                </div>
              </div>
            </template>
            <template #cell(addresses)="data">
              <div
                class="address-row"
                v-for="(ad, ad_index) in data.item.locations"
                :key="`add-row-${data.index}-${ad_index}`"
              >
                <span class="d-inline-block">{{ ad }}</span>
              </div>
            </template>
            <template #cell(information)="data">
              <OrderMeta :order="data.item" />
            </template>
            <template #cell(total)="data">
              <template
                v-if="
                  stringHelpers.paymentMethodConversion(
                    data.item.payment_method
                  ) == 'no-pay'
                "
              >
                <a href="#" @click.prevent="showNoPayCostModal(data.item)">
                  {{
                    data.item.no_pay_cost
                      ? `$${data.item.no_pay_cost}`
                      : "+cost"
                  }}
                </a>
              </template>
              <template v-else>${{ data.item.cost || 0 }}</template>
            </template>
            <template #foot(total)="data">
              <span class="lbl">{{ data.label }}</span>
              {{
                `$${driverSumBy("cost", (o) => {
                  return ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                    0 &&
                    o.payment_method &&
                    ["cash", "credit card", "e tranfer"].indexOf(
                      o.payment_method.toLowerCase()
                    ) >= 0;
                }) +
                  driverSumBy("no_pay_cost", (o) => {
                    return ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                      0 &&
                      o.payment_method &&
                      ["不用收錢", "不用收錢 bc"].indexOf(o.payment_method.toLowerCase()) >= 0;
                  })}`
              }}
            </template>
            <template #cell(driver_received)="data">
              {{
                data.item.payment_method == "Cash"
                  ? `$${data.item.cost}`
                  : "---"
              }}
            </template>
            <template #foot(driver_received)="data">
              <span class="lbl">{{ data.label }}</span>
              {{
                `$${driverSumBy("cost", function(o) {
                  return ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                    0 &&
                    o.payment_method &&
                    o.payment_method.toLowerCase() == "cash";
                })}`
              }}
            </template>
            <template #cell(company_received)="data">
              <template
                v-if="
                  data.item.payment_method &&
                    data.item.payment_method.toLowerCase() == 'credit card'
                "
              >
                ${{ data.item.cost }}
              </template>
              <template
                v-else-if="
                  (data.item.payment_method == '不用收錢' || data.item.payment_method == '不用收錢 BC') &&
                    data.item.no_pay_cost
                "
              >
                ${{ data.item.no_pay_cost }}
              </template>
              <template v-else>---</template>
            </template>
            <template #foot(company_received)="data">
              <span class="lbl">{{ data.label }}</span>
              {{
                `$${driverSumBy("cost", function(o) {
                  return ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                    0 &&
                    o.payment_method &&
                    o.payment_method.toLowerCase() == "credit card";
                }) +
                  driverSumBy("no_pay_cost", function(o) {
                    return ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                      0 &&
                      o.payment_method &&
                      (o.payment_method == "不用收錢" || o.payment_method == "不用收錢 BC");
                  })}`
              }}
            </template>
            <template #cell(cost_407)="data">
              <span class="d-inline-block align-middle">
                <template
                  v-if="
                    data.item.payment_method &&
                      ['credit card', '不用收錢', '不用收錢 bc', ].indexOf(
                        data.item.payment_method.toLowerCase()
                      ) >= 0
                  "
                >
                  ${{ data.item.cost_407 || 0 }}
                </template>
                <template v-else>---</template>
              </span>
              /
              <a
                class="d-inline-block align-middle"
                href="#"
                @click.prevent="showTipsModal(data.item)"
                v-html="data.item.tips ? `$${data.item.tips}` : '+tips'"
              ></a>
            </template>
            <template #foot(cost_407)>
              <span class="d-inline-block mr-3"
                >407 <br />
                {{
                  `$
                ${driverSumBy(
                  "cost_407",
                  (o) =>
                    ["confirmed", "waiting"].indexOf(o.status.toLowerCase()) >=
                      0 &&
                    o.payment_method &&
                    ["credit card", "不用收錢", '不用收錢 bc',].indexOf(
                      o.payment_method.toLowerCase()
                    ) >= 0
                )}`
                }}</span
              >
              <span class="d-inline-block"
                >Tips <br />
                {{ `$${driverSumBy("tips")}` }}</span
              >
            </template>
            <template #head(driver_received_onbehalf)>
              <span class="lbl">
                司機代收<br />
                <span style="font-size: .8em;">(包括收據)</span>
              </span>
            </template>
            <template #cell(driver_received_onbehalf)="data">
              ${{ data.item.on_behalf_cost }}
            </template>
            <template #foot(driver_received_onbehalf)>
              <span class="lbl">
                司機代收<br />
                <span style="font-size: .8em;">(包括收據)</span>
              </span>
              {{ `$${driverSumBy("on_behalf_cost")}` }}
            </template>
            <template #cell(receipt_cash)="data">
              {{
                data.item.payment_method == "Cash"
                  ? `$${data.item.receipt}`
                  : "---"
              }}
            </template>
            <template #foot(receipt_cash)="data">
              <span class="lbl">{{ data.label }}</span>
              {{
                `$${driverSumBy("receipt", function(o) {
                  return o.payment_method == "Cash";
                })}`
              }}
            </template>
            <template #cell(receipt_others)="data">
              {{
                data.item.payment_method != "Cash" && data.item.receipt
                  ? `$${data.item.receipt}`
                  : "---"
              }}
            </template>
            <template #foot(receipt_others)="data">
              <span class="lbl">{{ data.label }}</span>
              {{
                `$${driverSumBy("receipt", function(o) {
                  return o.payment_method != "Cash";
                })}`
              }}
            </template>
            <template #cell(remark)="data">
              <p class="remark-p" v-if="data.item.remark">
                <strong>Remark:</strong><br />
                {{ data.item.remark }}
              </p>
              <p class="remark-p" v-if="data.item.instruction">
                <strong>Driver instruction:</strong><br />
                {{ data.item.instruction }}
              </p>

              <a href="#" @click.prevent="showRemarkModal(data.item)">Edit</a>
            </template>
          </b-table>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import OrderMeta from "@/components/OrderMeta.vue";
import DateRangeOptions from "@/components/DateRangeOptions.vue";
import OrderModal from "@/components/OrderModal.vue";

import { cloneDeep, filter, sumBy, isFinite, uniqueId, sortBy } from "lodash";

export default {
  name: "DriverPage",
  components: { DateRangeOptions, OrderMeta, OrderModal },
  data: function() {
    return {
      driverColCollapsed: false,
      showOrderTable: false,
      driverSaving: {},
      selectedDriver: null,
      dateRange: this.dateHelpers.monthDateRange(),
      driverOrderFields: [
        {
          key: "datetime",
          label: "日期",
          stickyColumn: true,
        },
        {
          key: "addresses",
          label: "",
        },
        {
          key: "information",
          label: "訂單資訊",
        },
        {
          key: "total",
          label: "總生意額",
          tdClass: "text-center",
        },
        {
          key: "driver_received",
          label: "司機總收現金",
          tdClass: "text-center",
        },
        {
          key: "company_received",
          label: "總額",
          tdClass: "text-center",
        },
        {
          key: "cost_407",
          label: "407/Tips",
          tdClass: "text-center",
          thClass: "text-center",
        },
        /*{
          key: "company_received_tips",
          label: "小費",
          tdClass: "text-center",
        },*/
        {
          key: "driver_received_onbehalf",
          label: "司機代收 (包括收據)",
          tdClass: "text-center",
          tdAttr: function(value, key, item) {
            if (item.on_behalf_cost) {
              return {
                style: `background-color: rgba(26, 48, 206, .2); font-weight: bold;`,
              };
            } else return {};
          },
        },
        {
          key: "receipt_cash",
          label: "收據銀碼",
          tdClass: "text-center",
          tdAttr: function(value, key, item) {
            if (item.receipt && item.payment_method == "Cash") {
              return {
                style: `background-color: rgba(26, 48, 206, .2); font-weight: bold;`,
              };
            } else return {};
          },
        },
        {
          key: "receipt_others",
          label: "收據C/C",
          tdClass: "text-center",
        },
        {
          key: "remark",
          label: "備註",
        },
      ],
      driverOrders: [],
      newDriverForm: {},

      tipsModalVisible: false,
      noPayCostModalVisible: false,
      remarkModalVisible: false,
      modalDataItem: {},
    };
  },
  watch: {
    driver_orders: function(nv) {
      this.driverOrders = cloneDeep(nv);
    },
    dateRange(nv) {
      this.getDriverOrder();
    },
  },
  computed: {
    ...mapState({
      stored_drivers: (state) => state.drivers.drivers,
      driver_orders: (state) => state.orders.driverOrders,
      currentUser: (state) => state.user.userProfile,
    }),
    drivers() {
      return sortBy(Object.values(cloneDeep(this.stored_drivers)), function(d) {
        if (d.name) return d.name.toLowerCase();
        else return "";
      });
    },
  },
  // mounted() {
  //   this.$store.commit("orders/resetOrders");
  // },
  methods: {
    driverSumBy(key, filterBy = null) {
      let orders = this.driverOrders;
      if (filterBy) orders = filter(orders, filterBy);

      return sumBy(orders, (o) => {
        if (isFinite(o[key])) {
          return o[key];
        } else {
          return 0;
        }
      });
    },
    driverOrdersRowClass(item, type) {
      if (type == "row") {
        let pyConver = this.stringHelpers.paymentMethodConversion(
          item.payment_method
        );

        if (pyConver) return "pymt-" + pyConver;
      }

      return "";
    },
    toggleDriverCol() {
      this.driverColCollapsed = !this.driverColCollapsed;
    },
    ...mapActions({
      getOrders: "orders/get",
      setOrder: "orders/set",
      setDriver: "drivers/set",
      unsetDriver: "drivers/delete",
    }),
    selectDriver(driver) {
      this.selectedDriver = driver;
      this.getDriverOrder();
    },
    getDriverOrder() {
      this.getOrders({
        filters: {
          driverId: this.selectedDriver.driverId,
          ...this.dateRange,
        },
        stateKey: "driverOrders",
      });
      this.driverColCollapsed = true;
      this.showOrderTable = true;
    },
    async deleteDriver(driverId, index) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this driver? (It cannot be undone)"
        )
        .then(async (value) => {
          if (value) {
            await this.unsetDriver(driverId);

            this.drivers.splice(index, 1);

            this.$notify({
              text: "driver removed successfully",
              type: "success",
            });
          }
        });
    },
    async updateDriver(driver) {
      let savingDriverId = driver.driverId ?? uniqueId("driver_");

      this.$set(this.driverSaving, savingDriverId, true);
      await this.setDriver({ driverId: driver.driverId ?? null, data: driver });

      this.$notify({
        text: "更新成功",
        type: "success",
      });

      setTimeout(() => {
        this.$set(this.driverSaving, savingDriverId, false);
      }, 300);
    },
    async updateDriverOrder(order) {
      let savingOrder = cloneDeep(order);
      await this.setOrder({
        orderId: order.orderId,
        data: order,
        user: this.currentUser,
      });

      this.$notify({
        text: "更新成功",
        type: "success",
      });

      this.hideModals();
      this.orderUpdated();
    },
    orderUpdated() {
      this.getDriverOrder();
    },

    showTipsModal(dataItem) {
      this.tipsModalVisible = true;
      this.modalDataItem = dataItem;
    },
    showNoPayCostModal(dataItem) {
      this.noPayCostModalVisible = true;
      this.modalDataItem = dataItem;
    },
    showRemarkModal(dataItem) {
      this.remarkModalVisible = true;
      this.modalDataItem = dataItem;
    },
    hideModals() {
      this.tipsModalVisible = this.noPayCostModalVisible = this.remarkModalVisible = false;
      this.modalDataItem = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable";

#driver-row {
  &,
  > div {
    height: 100%;
  }
  > div {
    transition: 0.3s width;
  }

  &.driver-col-collapsed {
    #driver-search-col {
      width: 280px;
    }
    #driver-order-col {
      width: calc(100vw - 540px);

      .menu-collapsed & {
        width: calc(100vw - 390px);
      }
    }
  }
}
#driver-search-col {
  width: 570px;
}
#driver-order-col {
  width: calc(100vw - 830px);

  .menu-collapsed & {
    width: calc(100vw - 680px);
  }
}
.active-driver {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba($primary-color, 0.2);
}
.top-control {
  position: relative;
  z-index: 1;
}

.lbl {
  line-height: 1.2;
  display: block;
}

.b-table-sticky-header {
  height: calc(100% - 62px);

  .btn-link {
    padding: 0;
    text-decoration: underline;

    svg {
      font-size: 0.8em;
      vertical-align: -0.05em;
    }
  }
}

.payment-box {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  padding: 3px;
  display: inline-block;
}

.remark-p {
  min-width: 150px;
  margin-bottom: 5px;
}

.driver-top-title {
  padding-top: 0.75rem;
  overflow: hidden;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 1;
  margin-bottom: 0.75rem;

  .box-title {
    margin-bottom: 0.75rem;
  }
}
</style>
